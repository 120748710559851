<template>
    <el-main>
      <el-form class="el-form-search" label-width="120px">
        <el-form-item label="二维码ID：">
          <el-input size="small" v-model="searchForm.sn"></el-input>
        </el-form-item>
        <el-form-item label="关联订单号：">
          <el-input size="small" v-model="searchForm.order_sn"></el-input>
        </el-form-item>
        <el-form-item label="收益来源：">
          <el-select v-model="searchForm.type" size="small" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="wifi扫码" :value="1"></el-option>
            <el-option label="第三方活动" :value="2"></el-option>
            <el-option label="扫码点餐" :value="3"></el-option>
            <el-option label="提现" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产生日期：">
          <el-date-picker size="small" v-model="searchForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="收益所属身份：">
          <el-select v-model="searchForm.identity_type" size="small" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="服务商" :value="1"></el-option>
            <el-option label="商家" :value="2"></el-option>
            <el-option label="团长" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
          <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="sn" label="二维码ID" width="200" align="center"></el-table-column>
        <el-table-column label="收益所属身份" align="center">
          <template v-slot="{row}">
              {{row.identity_type==1?'服务商':row.identity_type==2?'商家':'团长'}}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="收益人姓名" align="center"></el-table-column>
        <el-table-column prop="mobile" label="收益人电话" align="center"></el-table-column>
        <el-table-column prop="deal_name" label="收益支出" align="center"></el-table-column>
        <el-table-column label="预估收益" align="center">
          <template v-slot="{row}">
              <div>{{row.income_type==1?'+':'-'}}￥{{row.amount}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_sn" label="关联订单号" align="center"></el-table-column>
        <el-table-column label="产生时间" align="center">
          <template v-slot="{row}">
              {{getDateformat(row.create_time)}}
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </template>
  
  <script>
  import Paging from '@/components/paging.vue';
  import { getDateformat } from '@/util/getDate.js'
  export default {
      components:{
          Paging
      },
      data(){
          return{
              getDateformat,
              searchForm:{
                  page:1,
                  rows:10,
                  sn:'',
                  order_sn:'',
                  type:0,
                  time:[],
                  identity_type:0,
              },
              dataList:[],
              total:0
          }
      },
      created(){
          this.getDataList()
      },
      methods:{
          updateData (val, status) {
              if (status == 0) {
                  this.searchForm.rows = val;
              } else {
                  this.searchForm.page = val;
              }
              this.getDataList()
          },
          clearSearch(){
              this.searchForm = {
                  page:1,
                  rows:10,
                  sn:'',
                  order_sn:'',
                  type:0,
                  time:[],
                  identity_type:0,
              }
              this.getDataList()
          },
          getDataList(style){
              if(style)this.searchForm.page=1
              let obj = {
                  page:this.searchForm.page,
                  rows:this.searchForm.rows,
              }
              if(this.searchForm.sn)obj.sn=this.searchForm.sn
              if(this.searchForm.order_sn)obj.order_sn=this.searchForm.order_sn
              if(this.searchForm.type)obj.type=this.searchForm.type
              if(this.searchForm.identity_type)obj.identity_type=this.searchForm.identity_type
              if(this.searchForm.time?.length) {
                  obj.start_time = Math.ceil(this.searchForm.time[0].getTime() / 1000)
                  obj.end_time = Math.ceil(this.searchForm.time[1].getTime() / 1000)
                  obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time
              }
              this.$axios.post(this.$api.serviceProvider.getEarningsDetail,obj)
              .then(res=>{
                  if(res.code===0){
                      this.dataList = res.result.list
                      this.total = res.result.total_number 
                  }else{
                      this.$message.warning(res.msg)
                  }
              })
          },
      }
  }
  </script>
  
  <style lang="less" scoped>
  .el-main{
      background-color: #fff;
  }
  </style>